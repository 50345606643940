<template>
  <div class="default-state">
    <SidebarDirectionList />
  </div>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex';
import SidebarDirectionList from '@/components/sidebar/SidebarDirectionList.vue';
import entity from '@/helpers/FetchEntities';

export default {
  name: 'SidebarIndex',
  components: {
    SidebarDirectionList
  },
  async mounted() {
    this.startLoading();
    if (!this.bounds) {
      const interval = setInterval(async () => {
        if (this.bounds) {
          clearInterval(interval);
          await entity.list();
          this.stopLoading();
        }
      }, 500);
    } else {
      await entity.list();
      this.stopLoading();
    }
  },
  computed: {
    ...mapGetters('layer', ['layer']),
    ...mapGetters('map', ['bounds'])
  },
  methods: {
    ...mapActions('loader', ['startLoading', 'stopLoading'])
  }
}
</script>

<style lang="scss" scoped>
.default-state {
  padding: 0 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
